/* global tw */
import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import { relatedBlog } from '~/queries' // eslint-disable-line
import Layout from '../components/layout'
import ContentBuilder from '../components/ContentBuilder'
import MiniDrawer from '../components/Drawer/MiniDrawer'
import PageBody from '../components/PageBody'
import PageWrapper from '../components/Structure/PageWrapper'
import styled from 'styled-components'

const Tinyhed = styled.h4`
  ${tw`text-xs uppercase tracking-wide mb-2`};
`

class BlogPost extends Component {
  render() {
    const {
      data: {
        craft: { post },
      },
    } = this.props

    var rado = post.projectInfo.overview
      ? post.projectInfo[0].overview.content
      : null

    var generatedTitle = post.projectInfo.headline
      ? post.projectInfo[0].headline
      : post.title

    var challenge = post.projectInfo.challenge
      ? post.projectInfo[0].challenge
      : 'carrots'

    var outcome = post.projectInfo.outcome
      ? post.projectInfo[0].outcome
      : 'noodles'

    return (
      <Layout location={this.props.location}>
        <PageWrapper
          pageTheme={post.pageTheme}
          mainContent={
            <div className="flex-none">
              {!post.hero.length ? (
                <img
                  src={'https://placehold.it/1240x520'}
                  alt={post.title}
                  className="img-fluid w-full"
                />
              ) : (
                <div>
                  <img
                    src={
                      post.hero[0].url +
                      '?w=1240&h=520&fit=crop&fp-x=' +
                      post.hero[0].focalPoint.x +
                      '&fp-y=' +
                      post.hero[0].focalPoint.y +
                      '&fp-debug=false'
                    }
                    alt={post.title}
                    className="img-fluid w-full"
                  />
                </div>
              )}
            </div>
          }
          title={generatedTitle}
          bonusContent={
            <div>
              <div className="w-full lg:w-5/6 mx-auto">
                <div className="flex flex-wrap -mx-4 py-12 text-white">
                  <div className="w-full md:w-2/3 px-4">
                    <PageBody body={rado} />
                  </div>
                  <div className="w-full md:w-1/4 px-4 ml-auto">
                    {challenge ? (
                      <div>
                        <Tinyhed>Challenge</Tinyhed>
                        <p className="text-base leading-normal ignoreReading mb-6">
                          {challenge}
                        </p>
                      </div>
                    ) : null}
                    {outcome ? (
                      <div>
                        <Tinyhed>Outcome</Tinyhed>
                        <p className="text-base leading-normal ignoreReading">
                          {outcome}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <ContentBuilder
                data={post}
                theming={post.pageTheme === 'light' ? 'light' : 'dark'}
              />
              <Link to="/work">Back to work</Link>
            </div>
          }
        />
      </Layout>
    )
  }
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByUri($uri: String!) {
    craft {
      post: entry(uri: $uri) {
        ...relatedBlog
      }
    }
  }
`

// <h1>Page Theme: {post.pageTheme}</h1>
