/* global tw */
import React from "react";
import styled from "styled-components";

const Body = styled.div`
	${tw``};
`;

const PageBody = props => {
	return props.body ? (
		<Body dangerouslySetInnerHTML={{ __html: props.body }} />
	) : null;
};

export default PageBody;
