/* global tw */
import React from 'react'
import PageBody from '../PageBody/PageBody'
import styled from 'styled-components'

const ContentArea = styled.div`
  ${tw`px-4 overflow-x-hidden`};
`

const Title = styled.h1`
  ${tw`text-4xl md:text-5xl lg:text-6xl font-graphik font-black md:font-bold  leading-none mt-2 mb-4 overflow-x-hidden`}
`

const FlexNone = styled.div`
  ${tw`flex-none leading-zero`};
`

const PageWrapper = ({
  headImage,
  mainContent,
  sidebarContent,
  body,
  title,
  subtitle,
  bonusContent,
  pageTheme,
  ...props
}) => {
  return (
    <div
      className={
        pageTheme == 'dark'
          ? 'px-4 md:px-8 md:mx-6 bg-black'
          : 'px-4 md:px-8 md:mx-6 bg-grey-lighter'
      }
    >
      <div className="flex flex-wrap -mx-4 mb-4 md:mb-12 bg-notblue">
        <ContentArea
          className={
            sidebarContent
              ? 'w-full md:w-3/4 reading'
              : 'w-full reading bg-notyellow'
          }
        >
          <FlexNone>{headImage}</FlexNone>

          {mainContent}
          <Title>{title}</Title>
          {subtitle}
          <PageBody body={body} />
          {bonusContent}
        </ContentArea>

        {sidebarContent ? (
          <div className="w-full md:w-1/4 px-4">{sidebarContent}</div>
        ) : null}
      </div>
    </div>
  )
}

export default PageWrapper

// ${props => (props.featured ? "0 0 100%" : "0 0 49%")};	${props => (props.featured ? "0 0 100%" : "0 0 49%")};
